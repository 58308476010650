import {
    ImageComponent as SourceImageComponent,
} from 'SourceComponent/Image/Image.component';
import { ImageState } from 'SourceComponent/Image/Image.config';
import { ReactElement } from 'SourceType/Common.type';

/** @namespace Inov8/Component/Image/Component */
export class ImageComponent extends SourceImageComponent {
    renderPlainImage(): ReactElement {
        const {
            alt,
            src,
            style,
            title,
            className,
        } = this.props;
        const { isLazyLoading } = this.state;

        const imgSizes: { height?: number; width?: number } = {
            height: undefined,
            width: undefined,
        };

        imgSizes.height = this.image.current?.clientHeight;
        imgSizes.width = this.image.current?.clientWidth;

        return (
            <img
              block={ className }
              src={ `${src}${imgSizes.width ? `?width=${imgSizes.width}` : ''}` }
              alt={ alt }
              ref={ this.image }
              style={ style }
              title={ title }
              height={ imgSizes.height }
              width={ imgSizes.width }
              onLoad={ this.onLoad }
              onError={ this.onError }
              loading={ isLazyLoading ? 'lazy' : 'eager' }
            />
        );
    }

    renderStyledImage(): ReactElement {
        const {
            alt,
            src,
            style,
            title,
        } = this.props;
        const {
            imageStatus,
            isLazyLoading,
        } = this.state;

        const imgSizes: { height?: number; width?: number } = {
            height: undefined,
            width: undefined,
        };

        imgSizes.height = this.image.current?.clientHeight;
        imgSizes.width = this.image.current?.clientWidth;

        return (
            <img
              block="Image"
              elem="Image"
              src={ `${src}${imgSizes.width ? `?width=${imgSizes.width}` : ''}` }
              alt={ alt }
              ref={ this.image }
              mods={ { isLoading: imageStatus === ImageState.IMAGE_LOADING } }
              style={ style }
              title={ title }
              onLoad={ this.onLoad }
              onError={ this.onError }
              height={ imgSizes.height }
              width={ imgSizes.width }
              loading={ isLazyLoading ? 'lazy' : 'eager' }
            />
        );
    }
}

export default ImageComponent;
